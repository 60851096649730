body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.pac-container {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10000;
}

.pac-matched {
    font-weight: bold;
}

/* Style the dropdown items */
.pac-item {
    padding: 10px;
    font-size: 15px;
    color: #333;
    cursor: pointer;
}

/* Style dropdown item when hovered */
.pac-item:hover {
    background-color: #f0f0f0;
}

/* Style the highlighted text in the dropdown */
.pac-item .pac-item-query {
    font-weight: bold;
    font-size: 14px;
    color: #1f6c9d; /* Customize to your theme color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

.sticky-cell {
  position: sticky;
  background: inherit;
  z-index: 2;
}

.checkbox-cell {
  left: 0;
}

.checkbox-cell + .sticky-cell {
  left: 32px;
}

.section-collapse {
  overflow: visible !important;
}
